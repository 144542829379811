<template>
  <div id="feeDetails">
    <header-nav :content="`${$t('pages_router_014')} / ${ $t('pages_router_019')}`" />
    <div class="common-card-body">
      <div class="edit-btn">
        <a-button
          shape="round"
          type="primary"
          ghost
          :loading="exportLoading"
          @click="exportFeeFlow"
        >
          <template #icon><download-outlined /></template>
          {{ exportLoading ? $t('common_text_023') : $t('pages_account_043') }}
        </a-button>
      </div>
      <div class="search-form ant-round-form">
        <div class="part flex-align">
          <span class="part-label">{{ $t('pages_account_031') }}: </span>
          <a-input
            v-model:value="searchQuery.batchNo"
            allow-clear
            @pressEnter="loadFeeFlow(1)"
            @blur="loadFeeFlow(1)"
          />
        </div>
        <div class="part flex-align">
          <span class="part-label">{{ $t('pages_account_032') }}: </span>
          <a-select
            v-model:value="searchQuery.busiType"
            allow-clear
            show-search
            @change="loadFeeFlow(1)"
          >
            <a-select-option
              v-for="business in busiTypeList"
              :key="business.code"
              :value="business.code"
            >{{ business.name }}</a-select-option>
          </a-select>
        </div>
        <div class="part flex-align">
          <span class="part-label">{{ $t('pages_account_033') }}: </span>
          <a-select
            v-model:value="searchQuery.status"
            allow-clear
            show-search
            @change="loadFeeFlow(1)"
          >
            <a-select-option
              v-for="status in statusList"
              :key="status.code"
              :value="status.code"
            >{{ status.name }}</a-select-option>
          </a-select>
        </div>
        <div class="part flex-align">
          <span class="part-label">{{ $t('pages_account_034') }}: </span>
          <a-range-picker
            v-model:value="searchQuery.dateOpts"
            format="YYYY/MM/DD"
            value-format="YYYY-MM-DD"
            :allow-clear="false"
            @change="loadFeeFlow(1)"
          />
        </div>
      </div>
      <div class="table-part">
        <a-table
          :scroll="{ x: 1500 }"
          :loading="loading"
          :pagination="false"
          :custom-header-row="() => { return {class: 'my-table-tr'}}"
          :columns="columns"
          :data-source="feeList"
        />
        <table-page
          :total="total"
          :page-opts="searchQuery"
          @update-page="updatePage"
        />
      </div>
    </div>
  </div>
</template>
<script>
import { reactive, toRefs, computed } from 'vue'
import HeaderNav from '@/components/header-nav'
import TablePage from '@/components/table-page'
import { _account } from '@/api'
import i18n from '@/locale'
import moment from 'moment'
import { message } from 'ant-design-vue'
import { parseMoney, getAttrStatusList, getTradeStatusName } from '@/util'
export default {
  name: 'FeeDetails',
  components: {
    'header-nav': HeaderNav,
    'table-page': TablePage
  },

  setup () {
    const state = reactive({
      loading: true,
      total: 0,
      exportLoading: false,
      searchQuery: {
        pageNum: 1,
        pageSize: 10,
        batchNo: '',
        busiType: '',
        status: '',
        dateOpts: [
          moment().subtract(30, 'days').format('YYYY-MM-DD'),
          moment().format('YYYY-MM-DD')
        ]
      },
      feeList: [],
      columns: [
        { key: 'batchNo', dataIndex: 'batchNo', title: i18n.global.t('pages_account_031'), width: 200 },
        { key: 'merSingleBatchNo', dataIndex: 'merSingleBatchNo', title: i18n.global.t('pages_account_035'), width: 180, ellipsis: true },
        { key: 'bizType', dataIndex: 'bizTypeText', title: i18n.global.t('pages_account_032'), width: 150 },
        { key: 'tradeCurrency', dataIndex: 'tradeCurrency', title: i18n.global.t('pages_account_036'), width: localStorage.getItem('locale-language') === 'en' ? 165 : 120 },
        { key: 'tradeAmt', dataIndex: 'tradeAmtText', title: i18n.global.t('pages_account_037'), width: localStorage.getItem('locale-language') === 'en' ? 165 : 130 },
        { key: 'feeCurrency', dataIndex: 'feeCurrency', title: i18n.global.t('pages_account_038'), width: localStorage.getItem('locale-language') === 'en' ? 165 : 120 },
        { key: 'feeTradeAmt', dataIndex: 'feeTradeAmtText', title: i18n.global.t('pages_account_039'), width: localStorage.getItem('locale-language') === 'en' ? 165 : 130 },
        { key: 'status', dataIndex: 'statusText', title: i18n.global.t('pages_account_040'), width: localStorage.getItem('locale-language') === 'en' ? 165 : 120 },
        { key: 'tradeTime', dataIndex: 'tradeTime', title: i18n.global.t('pages_account_041'), width: 180 },
        { key: 'remark', dataIndex: 'remark', title: i18n.global.t('pages_account_042'), width: 250, ellipsis: true }
      ]
    })

    state.busiTypeList = computed(() => {
      return getAttrStatusList('tranFlowBsnType')
    })
    state.statusList = computed(() => {
      return getAttrStatusList('feeStatus')
    })

    const updatePage = (page, pageSize) => {
      state.searchQuery.pageNum = page
      state.searchQuery.pageSize = pageSize
      loadFeeFlow()
    }

    const exportFeeFlow = async () => {
      const { dateOpts } = state.searchQuery
      if (moment(dateOpts[1]).diff(dateOpts[0], 'months') > 5) {
        message.error(i18n.global.t('pages_account_044'))
        return
      }

      state.exportLoading = true
      const query = Object.assign({}, state.searchQuery, {
        beginDate: `${dateOpts[0]} 00:00:00`,
        endDate: `${dateOpts[1]} 23:59:59`
      })
      await _account.exportFee(query)
      state.exportLoading = false
    }

    const loadFeeFlow = async (pageNum) => {
      state.loading = true
      if (pageNum) state.searchQuery.pageNum = pageNum
      const { dateOpts } = state.searchQuery
      const query = Object.assign({}, state.searchQuery, {
        beginDate: `${dateOpts[0]} 00:00:00`,
        endDate: `${dateOpts[1]} 23:59:59`
      })
      const res = await _account.getFeeRecord(query)
      if (res.data.succ) {
        state.feeList = res.data.data.list.map(item => {
          return Object.assign({}, item, {
            bizTypeText: getTradeStatusName(item.bizType, 'tranFlowBsnType'),
            feeTypeText: getTradeStatusName(item.feeType, 'feeType'),
            statusText: getTradeStatusName(item.status, 'feeStatus'),
            tradeCodeText: getTradeStatusName(item.tradeCode, 'feeTradeCode'),
            tradeAmtText: parseMoney(item.tradeAmt, item.tradeCurrency),
            feeTradeAmtText: parseMoney(item.feeTradeAmt, item.feeCurrency)
          })
        })
      }
      state.total = res.data.data.total
      state.loading = false
    }

    loadFeeFlow()

    return {
      updatePage,
      exportFeeFlow,
      loadFeeFlow,
      ...toRefs(state)
    }
  }
}
</script>
<style lang="less" scoped>
#feeDetails {
  .edit-btn {
    display: flex;
    justify-content: flex-end;
    padding: 0 18px;
  }
  .table-part {
    margin-top: 12px;
  }
}
</style>
